import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ETransactionType,
  Franchise,
  TypeTransaction,
  TypeTransactionService,
  Workshop,
  WorkshopTransaction,
  WorkshopTransactionService,
} from 'fullpits-commons';
import { ToastrService } from 'ngx-toastr';
import { ETypePerson } from 'src/app/core/enum/e-type-person.enum';
import { TokenValidateResponse } from 'src/app/core/interfaces/token-validate-response';
import { JwtDecodeService } from 'src/app/core/services/jwt-decode.service';

@Component({
  selector: 'app-transaction-form',
  templateUrl: './transaction-form.component.html',
  styleUrls: ['./transaction-form.component.css'],
})
export class TransactionFormComponent implements OnInit, OnDestroy {
  transactionForm!: UntypedFormGroup;
  transactionTypeList: TypeTransaction[] = [];
  @Input() selectedOperario?: Workshop;
  @Input() selectedTransaction?: WorkshopTransaction;
  @Input() selectedFranchise?: Franchise;
  @Input() selectedTransactionType?: TypeTransaction;
  @Output() onTransactionSavedEmmiter = new EventEmitter<boolean>();
  showTypeTransactionInput = true;

  user!: TokenValidateResponse;

  MAIN_FRANCHISE = 1;

  constructor(
    private toastrService: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private typeTransactionService: TypeTransactionService,
    private cd: ChangeDetectorRef,
    private workshopTransactionService: WorkshopTransactionService,
    private jwtDecodeService: JwtDecodeService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.user = this.jwtDecodeService.JwtValidated(
        <string>localStorage.getItem('token')
      );
    }
    this.initTransactionForm();

    this.typeTransactionService.getAll().subscribe({
      next: (value: TypeTransaction[]) => {
        this.transactionTypeList = value;
        if (
          this.transactionTypeList &&
          this.user &&
          this.user.user &&
          this.user.user.franchiseId &&
          this.user.user.franchiseId > this.MAIN_FRANCHISE
        ) {
          this.transactionTypeList = this.transactionTypeList.filter(
            (obj) => obj.id === ETransactionType.ADELANTO
          );
        }
        this.cd.markForCheck();
      },
      error: () => {
        this.toastrService.error('Error consultando tipos de transacciones');
      },
    });
  }

  initTransactionForm() {
    if (this.selectedTransaction) {
      let dateTransaction = new Date(this.selectedTransaction.dateTransaction!);

      this.transactionForm = this.formBuilder.group({
        amount: [
          this.selectedTransaction.amount,
          [Validators.required, Validators.min(0)],
        ],
        dateTransaction: [dateTransaction, Validators.required],
        dateApplied: [
          new Date(this.selectedTransaction.dateTransaction!),
          Validators.required,
        ],
        observation: [this.selectedTransaction.observation],
        typeTransaction: [
          this.selectedTransaction.typeTransaction?.id,
          Validators.required,
        ],
      });
    } else {
      this.transactionForm = this.formBuilder.group({
        amount: [0, [Validators.required, Validators.min(1)]],
        dateTransaction: [new Date(), Validators.required],
        dateApplied: ['', Validators.required],
        observation: [''],
        typeTransaction: [
          this.selectedTransactionType
            ? this.selectedTransactionType.id
            : ETransactionType.ADELANTO,
          Validators.required,
        ],
      });
    }

    if (
      this.selectedTransactionType ||
      (this.user && this.user && this.user.user.type !== ETypePerson.ADMIN)
    ) {
      this.showTypeTransactionInput = false;
    } else {
      this.showTypeTransactionInput = true;
    }
    this.cd.markForCheck();
  }

  saveTransaction() {
    if (!this.selectedTransaction) {
      this.selectedTransaction = new WorkshopTransaction();
    } else {
      this.selectedTransaction.id = this.selectedTransaction?.id;
    }

    this.selectedTransaction.workshop = this.selectedOperario;
    this.selectedTransaction.franchiseId = this.selectedFranchise?.id;
    this.selectedTransaction.amount = Number.parseFloat(
      this.transactionForm.controls['amount'].value
    );
    this.selectedTransaction.observation =
      this.transactionForm.controls['observation'].value;
    this.selectedTransaction.dateTransaction =
      this.transactionForm.controls['dateTransaction'].value;
    this.selectedTransaction.dateApplied =
      this.transactionForm.controls['dateApplied'].value;

    let idType = Number.parseInt(
      this.transactionForm.controls['typeTransaction'].value
    );

    let typeTransaction = this.transactionTypeList.find((t) => t.id === idType);
    if (typeTransaction) {
      //typeTransaction.id = idType;
      this.selectedTransaction.typeTransaction = typeTransaction;
    }
    if (!this.selectedFranchise) {
      if (this.user && this.user.user && this.user.user.franchiseId) {
        this.selectedTransaction.franchiseId = this.user.user.franchiseId;
      } else {
        this.selectedTransaction.franchiseId = this.MAIN_FRANCHISE;
      }
    }
    this.workshopTransactionService.save(this.selectedTransaction).subscribe({
      next: (value) => {
        this.cd.markForCheck();
        this.onTransactionSavedEmmiter.emit(true);
        this.toastrService.success('Transacción guardada exitosamente');
      },
      error: (err) => {
        this.onTransactionSavedEmmiter.emit(false);
        this.toastrService.error('Error guardando transacción');
      },
    });
  }

  ngOnDestroy(): void {
    this.selectedTransaction = new WorkshopTransaction();
  }
}
