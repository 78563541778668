import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Components
import { TransactionFormComponent } from './components/transaction-form/transaction-form.component';
import { ComunComponent } from './components/comun/comun.component';

// Toastr
import { ToastrModule } from 'ngx-toastr';

// Angular Material
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';

// PrimeNG
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DataViewModule } from 'primeng/dataview';
import { BadgeModule } from 'primeng/badge';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ChartModule } from 'primeng/chart';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TransactionFormComponent, ComunComponent],
  imports: [
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSelectModule,
    MatOptionModule,
    CalendarModule,
    SplitButtonModule,
    TableModule,
    ButtonModule,
    DataViewModule,
    InputTextModule,
    CheckboxModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    DropdownModule,
    MultiSelectModule,
    FileUploadModule,
    BadgeModule,
    MatAutocompleteModule,
    InputTextareaModule,
    InputNumberModule,
    ChartModule,
    CommonModule,
    ToastrModule.forRoot(), // ToastrModule added
  ],
  exports: [
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    CheckboxModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    SplitButtonModule,
    TableModule,
    DataViewModule,
    ButtonModule,
    InputTextModule,
    MatMenuModule,
    CalendarModule,
    MatCheckboxModule,
    DropdownModule,
    TabViewModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    FileUploadModule,
    DialogModule,
    BadgeModule,
    MatAutocompleteModule,
    InputNumberModule,
    ChartModule,
    TransactionFormComponent,
  ],
})
export class ComunModule {}
