import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import jwtDecode from 'jwt-decode';
import { TokenValidateResponse } from './core/interfaces/token-validate-response';
import { isPlatformBrowser } from '@angular/common';
import { ValidatedTokenService } from './core/services/validated-token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  installEvent: any = null;
  constructor(
    private swUpdate: SwUpdate,
    private cd: ChangeDetectorRef,
    private validatedTokenService: ValidatedTokenService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    this.getUpdate();
  }

  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:keypress', ['$event'])
  onUserActivity(event: Event) {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('token')) {
        const token: TokenValidateResponse = jwtDecode(
          localStorage.getItem('token')!
        );
        if (token.exp * 1000 - 4 * 60 * 60 * 1000 <= Date.now()) {
          // Metodo para renovar el token
          this.validatedTokenService
            .refreshToken(localStorage.getItem('token')!)
            .subscribe((resp) => {
              localStorage.setItem('token', resp.token);
              this.cd.detectChanges();
            });
        }
      }
    }
  }

  @HostListener('window: beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: Event) {
    event.preventDefault();
    this.installEvent = event;
  }
  installByUser() {
    if (this.installEvent) {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((rta: any) => {});
    }
  }
  getUpdate() {
    this.swUpdate.available.subscribe((valor) => {
      window.location.reload();
    });
  }
}
