<router-outlet></router-outlet>
<div
  *ngIf="installEvent"
  class="toast position-fixed bg-light bottom-0 start-50 translate-middle-x show"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
  style="z-index: 1"
>
  <div class="toast-body">
    Instalar app a mi Home Screen.
    <div class="mt-2 pt-2 border-top">
      <button mat-raised-button type="button" data-bs-dismiss="toast">
        Ahora no
      </button>
      <button
        mat-raised-button
        class="bg-naranja text-white"
        (click)="installByUser()"
        type="button"
        data-bs-dismiss="toast"
      >
        Instalar
      </button>
    </div>
  </div>
</div>
