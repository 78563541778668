import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-comun',
  templateUrl: './comun.component.html',
  styleUrls: ['./comun.component.css'],
})
export class ComunComponent implements OnInit {
  MAX_ROWS_PER_PAGE = 500;
  showForm = false;

  constructor(
    public cd: ChangeDetectorRef,
    public toastrService: ToastrService,
    public formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {}
}
