import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  TokenInvalidResponse,
  TokenValidateResponse,
} from '../interfaces/token-validate-response';

@Injectable({
  providedIn: 'root',
})
export class ValidatedTokenService {
  private urlBaseNode = environment.urlLoginBackend;
  constructor(private readonly _http: HttpClient) {}

  validatedToken(
    token: string
  ): Observable<TokenValidateResponse | TokenInvalidResponse> {
    return this._http.post<TokenValidateResponse | TokenInvalidResponse>(
      `${this.urlBaseNode}api/users/validated-token`,
      {
        token,
      }
    );
  }

  refreshToken(token: string): Observable<{ token: string }> {
    return this._http.post<{ token: string }>(
      `${this.urlBaseNode}api/users/refresh-token`,
      {
        token,
      }
    );
  }
}
