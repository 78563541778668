<form
  [formGroup]="transactionForm"
  (ngSubmit)="saveTransaction()"
  class="my-3 d-flex flex-column gap-3"
>
  <div class="d-flex flex-column gap-3">
    <div class="flex-grow-1">
      <span class="p-float-label">
        <p-inputNumber
          mode="currency"
          currency="COP"
          locale="es-CO"
          id="amount"
          styleClass="w-100"
          class="w-100"
          formControlName="amount"
          [maxFractionDigits]="0"
        >
        </p-inputNumber>
        <label htmlFor="amount">Monto</label>
      </span>
    </div>
    <div *ngIf="showTypeTransactionInput">
      <span class="p-float-label">
        <p-dropdown
          [options]="transactionTypeList"
          formControlName="typeTransaction"
          optionLabel="name"
          optionValue="id"
          styleClass="w-100"
          id="typeTransaction"
        ></p-dropdown>
        <label htmlFor="typeTransaction">Tipo de transacción</label>
      </span>
    </div>
  </div>
  <div class="d-flex flex-row gap-3 items-center">
    <div class="flex-grow-1">
      <span class="p-float-label">
        <p-calendar
          formControlName="dateApplied"
          styleClass="w-100"
          id="dateApplied"
        ></p-calendar>
        <label htmlFor="dateApplied">Fecha de aplicación</label>
      </span>
    </div>
    <div class="flex-grow-1">
      <span class="p-float-label">
        <p-calendar
          formControlName="dateTransaction"
          styleClass="w-100"
          id="dateTransaction"
        ></p-calendar>
        <label htmlFor="dateTransaction">Fecha de transacción</label>
      </span>
    </div>
  </div>
  <div>
    <span class="p-float-label">
      <textarea
        id="observation"
        formControlName="observation"
        pInputTextarea
        rows="1"
        class="w-100"
        [autoResize]="true"
      ></textarea>
      <label for="observation">Observaciones</label>
    </span>
  </div>
  <button
    pButton
    label="Aceptar"
    type="submit"
    [disabled]="transactionForm.invalid"
  ></button>
</form>
