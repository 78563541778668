export const environment = {
  production: false,
  // ********************** DEVELOPMENT ******************************
  // urlBackend: 'http://localhost:8087/',
  // apiUrlFullPitsNest: 'http://localhost:8087/',
  // urlLoginBackend: 'http://localhost:8084/',
  // urlLogin: 'http://localhost:4200/',
  // urlFrontoffice: 'http://localhost:4500/',
  // urlChat: 'http://localhost:4600/',
  // ********************** DEVELOPMENT ******************************
  urlBackend: 'https://nest.devinndexco.link/',
  urlLoginBackend: 'https://node-login.devinndexco.link/',
  apiUrlFullPitsNest: 'https://nest.devinndexco.link/',
  urlLogin: 'https://login.devinndexco.link/',
  urlFrontoffice: 'https://admin.devinndexco.link/',
  urlChat: 'https://messaging.devinndexco.link/',
  // ********************** PRODUCTION ******************************
  // urlBackend: 'https://nest.fullpits.com/',
  // urlLoginBackend: 'https://nodelogin.fullpits.com/',
  // urlLogin: 'https://login.fullpits.com/',
  // urlFrontoffice: 'https://admin.fullpits.com/',
  // apiUrlFullPitsNest: 'https://nest.fullpits.com/',
  // urlChat: 'https://messaging.fullpits.com/',
};
