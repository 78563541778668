import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { TokenValidateResponse } from '../interfaces/token-validate-response';

@Injectable({
  providedIn: 'root',
})
export class JwtDecodeService {
  constructor() {}

  JwtValidated(token: string): TokenValidateResponse {
    return jwt_decode(token);
  }
}
